<script setup lang="ts">
const { t } = useT();
const localPath = useLocalePath();
const loginGuard = useLoginGuard();
const { open } = useFunrizeModals();
const isGuest = useIsGuest();
const { isCanPlay, BALLS, collectedBalls } = useBingoData({ immediate: false });

const emit = defineEmits<{ (event: "toggleSidebar"): void }>();

const title = computed(() => {
	if (isGuest.value) {
		return t("Play Bingo Lotto & Get Prizes");
	}
	if (isCanPlay.value) {
		return t("Play to win free Coins & Entries");
	}
	return t("Play BINGO for free Coins & Entries");
});

const handleClick = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			navigateTo(localPath("/issues/popular-games/"));
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
	emit("toggleSidebar");
};

const handleOpenHiwModal = () => {
	loginGuard({
		success: () => {
			open("LazyOModalBingoHowItWorks");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
	emit("toggleSidebar");
};
</script>

<template>
	<div class="sidebar-bingo" @click="handleClick">
		<NuxtImg
			src="/nuxt-img/bingo/aside-banner.webp"
			class="sidebar-img"
			width="80"
			height="134"
			format="avif"
			alt="sidebar-img"
			loading="lazy"
		/>
		<AText :size="10" :modifiers="['bold']">
			{{ title }}
		</AText>
		<ABadge v-if="isCanPlay" variant="info" background="var(--curug)" class="counter" autosize>
			<AText :size="8" class="text-cannes" :modifiers="['condensed', 'bold']">
				<i18n-t keypath="{count}/{limit}">
					<template #count>
						<AText :size="8" class="text-cixi" :modifiers="['condensed', 'bold']">
							{{ collectedBalls }}
						</AText>
					</template>
					<template #limit>
						{{ BALLS }}
					</template>
				</i18n-t>
			</AText>
		</ABadge>
		<div class="action-wrapper">
			<MBingoProgress v-if="!isGuest && !isCanPlay" />
			<template v-else>
				<AButton variant="primary" size="xs">
					<AText :size="12" :modifiers="['bold', 'uppercase']">{{
						isGuest ? t("Play now") : t("Play bingo lotto")
					}}</AText>
				</AButton>
				<NuxtIcon name="20/info-bold" class="info-icon" filled @click.stop.prevent="handleOpenHiwModal" />
			</template>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sidebar-bingo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 4px;
	flex-shrink: 0;
	border-radius: 8px;
	cursor: pointer;
	position: relative;
	height: 67px;
	padding: 8px;
	background: var(--gushikawa);
	color: var(--cannes);

	.sidebar-img {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 40px;
		height: 67px;
	}

	.action-wrapper {
		display: flex;
		gap: 4px;
		align-items: center;
	}

	.info-icon:hover {
		cursor: pointer;
	}

	.counter {
		display: inline-flex;
		border-radius: 20px;
		position: absolute;
		right: 4px;
		top: 4px;
	}

	button {
		width: max-content;
		padding: 8px;
		min-width: 120px;
		border-radius: 50px;
	}
}
</style>
